export const es_brand = {
    /******************/
    /** Page titles */

    page_home: "Inicio| Orozino",
    page_games: "Juegos | Orozino",
    page_game: "Juego | Orozino",
    page_login: "Iniciar sesión | Orozino",
    page_registration: "Registrarse | Orozino",
    page_cashier: "Cajero | Orozino",
    page_profile: "Perfil | Orozino",
    page_forgot_password: "¿Olvidaste tu contraseña? | Orozino",
    page_reset_password: "Restablecer contraseña | Orozino",
    page_region_blocked: "Región bloqueada | Orozino",
    page_verify_email: "Verificación de correo electrónico | Orozino",

    /******************/
    /** Brand Pages */

    // Home
    last_played_10: "Vuelve a jugar",
    last_played_20: "Cápsula del tiempo",

    /******************/
    /** Brand components */

    // CashbackRoll.js
    cashback_roll_title: "¡Elige y gana!",
    cashback_roll_title_deposit: "¡Recarga y prueba tu suerte!",
    cashback_roll_subtitle: <span>¡Obtén tu premio en efectivo al reclamar tu primer&nbsp;bono!</span>,
    cashback_roll_subtitle_first_deposit: "¡Elige una carta y gana dinero en efectivo al instante!",

    cashback_roll_next_ticket: "Tu próxima carta estará disponible en:",
    cashback_roll_deposit_title: "¡Haz un depósito y gana un premio en efectivo al instante!",
    cashback_roll_btn_deposit: "Deposita ahora",
    cashback_roll_tickets_left: "Boletos:",
    cashback_roll_you_won: "¡Ganaste!",
    cashback_roll_notification: ({ sign, amount }) => <span><span style={{ fontWeight: "600" }}>{sign}{amount}</span> se ha acreditado en tu cuenta</span>,

    // HomeBanner.js
    home_banner_log_out_title_1: "Bono de bienvenida",
    home_banner_log_out_title_2: <><span className="title-lg home_baner-promo_font">100 % + 500 giros gratis!</span></>,
    home_banner_log_out_title_3: <><span className="subtitle-lg home_baner-promo_font">+ 30 giros gratis</span> sin <span className="subtitle-lg home_baner-promo_font">depósito</span></>,
    home_banner_log_in_title: "¡Bienvenido a Orozino!",
    home_banner_log_in_title_name: function (name) { return `Bienvenido, ${name}` },
    home_banner_log_in_text: "¡Que encuentres tu tesoro hoy!",
    home_banner_log_in_deposit_text: "¡Que encuentres tu tesoro hoy!",
    home_banner_log_in_deposit_cta: "Deposita ahora",
    home_banner_log_in_vip_banner_surtitle: "Una experiencia de casino de élite",
    home_banner_log_in_vip_banner_title: <>Deposita un total de <span className="primary"><b>$500</b></span> para obtener <span className="primary"><b>beneficios VIP</b></span>.</>,
    home_banner_log_in_vip_banner_text: (
        <>
            <li>Pagos de alta prioridad</li>
            <li>Reembolso mensual de más del 30%</li>
            <li>Bonos y regalos exclusivos</li>
            <li>Gerente personal</li>
        </>
    ),

    // ResponsibleGaming.js
    responsible_gaming_title: "Juego responsable",
    responsible_gaming_text: "Creemos que es nuestra responsabilidad con nuestros jugadores y la comunidad de juegos educar y brindar apoyo en el momento adecuado.",
    responsible_gaming_cta: "Más información",

    // FirstDepositPromo.js
    registration_banner_title: <>Obtén<span className="verify-phone-title-spins">30 giros gratuitos</span>sin depósito necesario!</>,
    registration_banner_subtitle: "SIN DEPÓSITO NECESARIO",
    fd_promo_deposit_title_interac: "Transacciones seguras con Interac!",
    fd_promo_deposit_subtitle_interac: "®Marca registrada de Interac Corp. Utilizada bajo licencia.",
    fd_promo_deposit_title_pix: "Seguro y confiable con PIX!",
    fd_promo_deposit_subtitle_pix: "Usado bajo licencia. Proveedor oficial de pagos.",
    deposit_banner_title_lottery: "Completa tu depósito y gana hasta",
    deposit_banner_vip_title: <>Depósita un total de <span className="primary"><b>$500</b></span><br />para obtener <span className="primary"><b>beneficios VIP</b></span>.</>,

    // GoogleButton.js
    google_button_text: "Continúa con Google.",

    // Footer.js
    footer_legal: "Orozino.com es operado por Spinsoft Interactive N.V. (Reg. N° 160756), Abraham de Veerstraat 9, Willemstad, P.O. Box 3421, Curazao, con todos los derechos para operar el software de juegos y/o ofrecer juegos de azar bajo la licencia de Anjouan N° ALS l-192407033-Fl2. Para cualquier ayuda, contáctenos en info@orozinosupport.com. Juega de manera responsable.",
    footer_legal_mock: <span>
        Esta declaración sirve como una notificación formal de que el contenido o la propiedad intelectual en cuestión está protegido por las leyes de derechos de autor. Indica que el creador o el titular de los derechos ha reservado todos los privilegios otorgados por dichas leyes, como el derecho a reproducir, distribuir y adaptar la obra.
        <br /><br />
        El juego responsable desempeña un papel fundamental en garantizar una experiencia positiva y placentera para los participantes, promoviendo prácticas seguras que ayudan a las personas a gestionar sus actividades dentro de sus límites. De este modo, se protege contra los riesgos de la adicción y los desafíos relacionados, al tiempo que se contribuye al bienestar general de la comunidad, fomentando un entorno de juego seguro, inclusivo y responsable.
        <br /><br />
        Derechos de autor © 2024 «Orozino»
        Todos los derechos reservados y protegidos por la ley.
    </span>,
    footer_btn_terms: "Términos y condiciones",
    footer_btn_privacy_policy: "Política de privacidad",
    footer_btn_responsible_gaming: "Juego responsable",
    footer_btn_dispute_resolution: "Resolución de disputas",
    footer_btn_self_exclusion: "Autoexclusión",
    footer_btn_terms_href: "https://cepgucehw.gleap.help/en/articles/1-terms-and-conditions",
    footer_btn_privacy_policy_href: "https://cepgucehw.gleap.help/en/articles/4-privacy-policy",
    footer_btn_responsible_gaming_href: "https://cepgucehw.gleap.help/en/articles/3-responsible-gaming",
    footer_btn_dispute_resolution_href: "https://cepgucehw.gleap.help/en/articles/5-dispute-resolution",
    footer_btn_self_exclusion_href: "https://cepgucehw.gleap.help/en/articles/2-self-exclusion",

    // QuestionMarks
    question_mark_bonus: (
        <>
            <ul>
                <li>Apostar x30.</li>
                <li>Retiro sin límite.</li>
                <li>Bono válido por 30 días.</li>
            </ul>
            <p className="mb-0">Los giros gratis están disponibles al instante.</p>
        </>
    ),
    question_mark_pick_and_win: (
        <ul>
            <li>El juego "Pick & Win" está disponible después de cada depósito.</li>
            <li>Para recibir tu premio en efectivo instantáneo, debes elegir una de las tres cartas.</li>
            <li>Tendrás 2 intentos. Vuelve mañana y prueba tu suerte de nuevo.</li>
        </ul>
    ),
    question_mark_deposit_lottery: (
        <ul>
            <li>Ganarás tu premio en efectivo instantáneo jugando al exclusivo juego de "Pick & Win" de Orozino.</li>
            <li>Para recibir tu premio en efectivo, debes elegir una de las tres cartas disponibles en la página principal.</li>
            <li>El juego "Pick & Win" estará disponible inmediatamente después de tu primer depósito.</li>
        </ul>
    ),
    question_mark_jackpot: (
        <ul>
            <li>Los jackpots diarios y horarios se otorgan al azar a los jugadores con cada giro (apuesta).</li>
            <li>Cada giro (apuesta) participa en el sorteo de los jackpots.</li>
        </ul>
    ),
    question_mark_bonus_balance: (
        <ul>
            <li>El bono debe ser apostado x30 antes de que los fondos estén disponibles para su retiro.</li>
            <li>Cada giro (apuesta) elegible contribuye con un 100% al cumplimiento de los requisitos de apuesta.</li>
            <li>Puedes retirar tus fondos antes de cumplir con los requisitos de apuesta del bono, sin embargo, todo el dinero del bono será confiscado.</li>
        </ul>
    ),

    // DepositMethod.js
    deposit_method_minimum_deposit_amount: "El monto mínimo para los pagos a continuación es de",

    //RetentionModals.js
    welcome_modal_cta: "¡Reclama ahora!",

    //HomeBannerTheme.js
    home_banner_log_out_surtile_theme: "OFERTA DE BIENVENIDA",
    home_banner_log_out_title_theme: "¡100% + 500 giros gratis!",
    home_banner_log_out_subtitle_theme: <><span className="primary">+ 30 giros gratis</span> sin <span className="primary">necesidad de depósito</span></>,
    home_banner_log_out_subtitle_theme_10: <><span className="primary">+ 10 giros gratis</span> sin <span className="primary">necesidad de depósito</span></>,
    home_banner_log_out_subtitle_theme_50: <><span className="primary">+ 50 giros gratis</span> sin <span className="primary">necesidad de depósito</span></>,
    home_banner_log_out_subtitle_theme_100: <><span className="primary">+ 100 giros gratis</span> sin <span className="primary">necesidad de depósito</span></>,
    home_banner_log_out_subtitle_theme_custom: (spinAmount) => <span className="primary">{`+ ${spinAmount} giros grati sin necesidad de depósito`}</span>,
    home_banner_log_out_cta_theme: "¡Reclama ahora!"
}
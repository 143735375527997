export default function isValidBonus({ bonus, user, type, showHidden, themeId }) {

    /** Checks for bonus for logged in users */
    if (bonus && Object.keys(user).length > 0) {

        /** Getting the currency option */
        const option = bonus.currencyList.filter(item => {
            return item.currency.id === user.currency.id;
        })[0];

        /** Check if bonus supports user currency */
        if (!option) { return false; }

        /** If user already claimed the bonus */
        if (!bonus.isInfinite && user.bonusesClaimed?.includes(bonus.promoCode)) { return false; }
        //if (user.bonusesClaimed?.includes(bonus.promoCode)) { return false; }

        /** If bonus deposit flow  position is different */
        if (bonus.depositFlowPosition !== user.depositsMade + 1 && bonus.depositFlowPosition !== 0) { return false; }

        /** If this is a hidden bonus validated through search */
        if (bonus.isHidden && !showHidden) { return false; }

        /** Type can be ALL, FREE, DEPOSIT */
        if (type !== 'ALL' && type !== bonus.activationType) { return false; }

        /** If the current player is eligible for the bonus  */
        if (bonus?.playerList?.length > 0 && !bonus?.playerList?.includes(user.id)) { return false; }

        /** Check if a bonus is a part of a theme */
        if (bonus?.themes?.length > 0) {

            /** If no theme id is supplied */
            if (!themeId) return false;

            /** Check if the theme matches */
            const theme = bonus.themes.filter(theme => {
                return theme.id === themeId;
            })[0];

            if (!theme) { return false; }
        }

        /** Check if a bonus is excluded from a theme */
        if (bonus?.excludedThemes?.length > 0 && themeId) {

            /** Check if the theme matches */
            const theme = bonus.excludedThemes.filter(theme => {
                return theme.id === themeId;
            })[0];

            if (theme) { return false; }
        }

        return true;
    }
    else if (bonus) {
        
        /** Check if a bonus is a part of a theme */
        if (bonus?.themes?.length > 0) {

            /** If no theme id is supplied */
            if (!themeId) return false;

            /** Check if the theme matches */
            const theme = bonus.themes.filter(theme => {
                return theme.id === themeId;
            })[0];

            if (!theme) { return false; }
        }

        /** Check if a bonus is excluded from a theme */
        if (bonus?.excludedThemes?.length > 0 && themeId) {

            /** Check if the theme matches */
            const theme = bonus.excludedThemes.filter(theme => {
                return theme.id === themeId;
            })[0];

            if (theme) { return false; }
        }

        return true;
    }

    return false;
}
import React from "react";
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

/** Utilities */
import { totalBalance } from "../spinsoft-core-client-subtree/utilities/balance";
import useText from "../spinsoft-core-client-subtree/utilities/use-text";

export default function HomeBannerThemeWolfGold({spinAmount}) {
    const userLoggedIn = useSelector((state) => state.user.isLoggedIn);
    const text = useText();

    return (
        <Container fluid="lg" className="section-margin">
            {
                userLoggedIn ? <LoggedInContent /> : (
                    <div className="home_banner_container_theme home_banner_container_theme_wolf_gold animate__animated animate__faster animate__fadeIn">
                        <div className="home_banner_content_wrapper_theme">
                            <div className="home_banner_content_theme">
                                <div className="animate__animated animate__fadeInUp">
                                    <img className="home_banner_images_theme" src="https://spt-assets.b-cdn.net/orozino/themes/wolf_gold/home_banner_image.png" />
                                </div>
                                <div className="text-center">
                                    <div className="home_banner_content_background_theme animate__animated animate__fadeIn animate__delay-1s">
                                        <span className='subtitle mb-1 surtile_theme'>{text('home_banner_log_out_surtile_theme')}</span>
                                        <h1 className='title title_theme'>{text('home_banner_log_out_title_theme')}</h1>
                                        <p className="subtitle mb-0 subtitle_theme">{text('home_banner_log_out_subtitle_theme_custom', spinAmount)}</p>
                                    </div>
                                    <img width="250px" src="https://spt-assets.b-cdn.net/orozino/themes/default/registration_banner_badge.png" />
                                    
                                    <div className="mt-3 mb-4">
                                        <Link to="?register" className="btn btn-main mt-2">{text('home_banner_log_out_cta_theme')}</Link>
                                    </div>

                                    <img width="250px" src="https://spt-assets.b-cdn.net/orozino/themes/default/registration_banner_payments_white.png" style={{opacity: "0.9"}} />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </Container>
    );
}

function LoggedInContent() {
    const user = useSelector((state) => state.user.data);
    const text = useText();

    const balanceCutOff = 15;

    return (
        <div className="home_banner-logged-in">
            <div className="home_banner-greeting">
                {
                    user?.firstName?.length > 0 ? (
                        <h1 className='title font-full mb-0' >{text('home_banner_log_in_title_name', user.firstName)}</h1>
                    ) : (
                        <h1 className='title font-full mb-0' >{text('home_banner_log_in_title')}</h1>
                    )
                }
            </div>

            <p className='font-mid mb-0 mt-1' >{text('home_banner_log_in_text')}</p>

            {
                parseFloat(totalBalance(user)) < balanceCutOff && user.depositsMade > 0 && (
                    <div>
                        <Link to={`?cashier`} className="btn btn-main mt-3">{text('home_banner_log_in_deposit_cta')}</Link>
                    </div>
                )
            }
        </div>
    )
}
export const en_brand = {
    /******************/
    /** Page titles */

    page_home: "Home | Orozino",
    page_games: "Games | Orozino",
    page_game: "Game | Orozino",
    page_login: "Login | Orozino",
    page_registration: "Register | Orozino",
    page_cashier: "Cashier | Orozino",
    page_profile: "Profile | Orozino",
    page_forgot_password: "Forgot Password | Orozino",
    page_reset_password: "Reset Password | Orozino",
    page_region_blocked: "Region Blocked | Orozino",
    page_verify_email: "Email Verification | Orozino",

    /******************/
    /** Brand Pages */

    // Home
    last_played_10: "Jump back in",
    last_played_20: "Time capsule",

    /******************/
    /** Brand components */

    // CashbackRoll.js
    cashback_roll_title: "Pick & Win!",
    cashback_roll_title_deposit: "Top up and try your luck!",
    cashback_roll_subtitle: <span>Unlock your cash prize by claiming your first&nbsp;bonus!</span>,
    cashback_roll_subtitle_first_deposit: "Pick a card and win instant cash!",

    cashback_roll_next_ticket: "Next pick available in:",
    cashback_roll_deposit_title: "Deposit and win instant money prize!",
    cashback_roll_btn_deposit: "Deposit now",
    cashback_roll_tickets_left: `Tickets:`,
    cashback_roll_you_won: `You won`,
    cashback_roll_notification: ({ sign, amount }) => <span><span style={{ fontWeight: "600" }}>{sign}{amount}</span> has been added to your account</span>,

    // HomeBanner.js
    home_banner_log_out_title_1: 'Welcome bonus',
    home_banner_log_out_title_2: <><span className="title-lg home_baner-promo_font">100% + 500 free spins!</span></>,
    home_banner_log_out_title_3: <><span className="subtitle-lg home_baner-promo_font">+ 30 free spins</span> with <span className="subtitle-lg home_baner-promo_font">no deposit</span></>,
    home_banner_log_in_title: 'Welcome to Orozino!',
    home_banner_log_in_title_name: function (name) { return `Welcome, ${name}` },
    home_banner_log_in_text: 'May you strike gold today!',
    home_banner_log_in_deposit_text: 'May you strike gold today!',
    home_banner_log_in_deposit_cta: "Deposit now",
    home_banner_log_in_vip_banner_surtitle: "An elite casino experience",
    home_banner_log_in_vip_banner_title: <>Deposit a total of <span className="primary"><b>$500</b></span> to enjoy our unique <span className="primary"><b>VIP benefits</b></span>.</>,
    home_banner_log_in_vip_banner_text: (
        <>
            <li>High priority payouts</li>
            <li>30%+ monthly cashback</li>
            <li>Exclusive bonuses and gifts</li>
            <li>Personal manager</li>
        </>
    ),

    // ResponsibleGaming.js
    responsible_gaming_title: 'Responsible gaming',
    responsible_gaming_text: 'We believe that it is our responsibility to our players and the gaming community to educate and provide support at the right moment.',
    responsible_gaming_cta: 'Learn more',

    // FirstDepositPromo.js
    // first_deposit_promo_badge: "LIMITED TIME OFFER",
    // first_deposit_promo_title: <span>Claim any bonus and get a chance to win&nbsp;<span className="fd_promo_highlight_registration">iPhone&nbsp;15!</span></span>,
    // first_deposit_promo_title_home: <span>Get a chance to win <span className="fd_promo_highlight">iPhone&nbsp;15</span> by claiming your first bonus!</span>,
    // first_deposit_promo_title_registration: <span>Claim your bonus and get a chance to win&nbsp;<span className="fd_promo_highlight_registration">iPhone&nbsp;15!</span></span>,
    // first_deposit_promo_cta: 'Enter the draw',
    // first_deposit_promo_cta_home: 'Claim now',
    registration_banner_title: <>Get <span className="verify-phone-title-spins">30 free spins</span> with no deposit required!</>,
    registration_banner_subtitle: 'NO DEPOSIT REQUIRED',
    fd_promo_deposit_title_interac: 'Safe & Secure with Interac!',
    fd_promo_deposit_subtitle_interac: '®Trade-mark of Interac Corp. Used Under License.',
    fd_promo_deposit_title_pix: 'Safe & Secure with PIX!',
    fd_promo_deposit_subtitle_pix: 'Used Under License. Official payment partner.',
    deposit_banner_title_lottery: 'Complete your deposit and win up to',
    deposit_banner_vip_title: <>Deposit a total of <span className="primary"><b>$500</b></span><br />to unlock unique <span className="primary"><b>VIP benefits</b></span>.</>,

    // GoogleButton.js
    google_button_text: 'Continue with Google',

    // Footer.js
    footer_legal: "Orozino.com is operated by Spinsoft Interactive N.V. (Reg no. 160756), Abraham de Veerstraat 9, Willemstad, P.O. Box 3421, Curaçao, which having all the rights to operate the gaming software and/or offer games of chance under the Anjouan License no. ALS l-192407033-Fl2. For any help please contact us at info@orozinosupport.com. Play responsibly.",
    footer_legal_mock: <span>
        This statement serves as a formal declaration that the content or intellectual property in question is protected under copyright laws. It signifies that the creator or the rights holder has reserved all the privileges provided by copyright law, such as the right to reproduce, distribute, and adapt the work.
        <br /><br />
        Responsible gambling plays a vital role in ensuring a positive and enjoyable experience for participants, promoting safe practices that help individuals manage their activities within their limits, thereby safeguarding against the risks of addiction and its related challenges, while also contributing to the overall well-being of the community by fostering a secure, inclusive, and responsible gaming environment.
        <br /><br />
        Copyright © 2024 «Orozino»
        All rights reserved and protected by law.
    </span>,
    footer_btn_terms: "Terms and conditions",
    footer_btn_privacy_policy: "Privacy policy",
    footer_btn_responsible_gaming: "Responsible gaming",
    footer_btn_dispute_resolution: "Dispute resolution",
    footer_btn_self_exclusion: "Self exclusion",
    footer_btn_terms_href: "https://cepgucehw.gleap.help/en/articles/1-terms-and-conditions",
    footer_btn_privacy_policy_href: "https://cepgucehw.gleap.help/en/articles/4-privacy-policy",
    footer_btn_responsible_gaming_href: "https://cepgucehw.gleap.help/en/articles/3-responsible-gaming",
    footer_btn_dispute_resolution_href: "https://cepgucehw.gleap.help/en/articles/5-dispute-resolution",
    footer_btn_self_exclusion_href: "https://cepgucehw.gleap.help/en/articles/2-self-exclusion",



    // QuestionMarks
    question_mark_bonus: (
        <>
            <ul>
                <li>Wager x30.</li>
                <li>No limit cashout.</li>
                <li>Bonus valid for 30 days.</li>
            </ul>
            <p className="mb-0">Free spins become available instantly.</p>
        </>
    ),
    question_mark_pick_and_win: (
        <ul>
            <li>"Pick & Win" game is available after every deposit.</li>
            <li>To receive your instant cash prize you need to pick one of three cards.</li>
            <li>You will have 2 attempts. Come back the next day and try your luck again.</li>
        </ul>
    ),
    question_mark_deposit_lottery: (
        <ul>
            <li>You will win your instant cash prize by playing the Orozino exclusive "Pick & Win" game.</li>
            <li>To receive your cash prize you need to pick one of three cards available on the home page.</li>
            <li>"Pick & Win" game is available instantly after your first deposit.</li>
        </ul>
    ),
    question_mark_jackpot: (
        <ul>
            <li>Daily and hourly jackpots are randomly awarded to players upon a spin (bet).</li>
            <li>Every spin (bet) participates in the jackpots' draw.</li>
        </ul>
    ),
    question_mark_bonus_balance: (
        <ul>
            <li>Bonus needs to be wagered x30 before the funds become available for withdrawal.</li>
            <li>Every eligible spin (bet) contributes 100% towards completing the wagering requirements.</li>
            <li>You can withdraw your funds prior to meeting bonus wagering requirements, however all bonus money will be forfeited.</li>
        </ul>
    ),

    // DepositMethod.js
    deposit_method_minimum_deposit_amount: 'Minimum amount for payments below is',

    //RetentionModals.js
    welcome_modal_cta: "Claim now",

    //HomeBannerTheme.js
    home_banner_log_out_surtile_theme: "WELCOME OFFER",
    home_banner_log_out_title_theme: "100% + 500 free spins!",
    home_banner_log_out_subtitle_theme: <><span className="primary">+ 30 free spins</span> with <span className="primary">no deposit required</span></>,
    home_banner_log_out_subtitle_theme_10: <><span className="primary">+ 10 free spins</span> with <span className="primary">no deposit required</span></>,
    home_banner_log_out_subtitle_theme_50: <><span className="primary">+ 50 free spins</span> with <span className="primary">no deposit required</span></>,
    home_banner_log_out_subtitle_theme_100: <><span className="primary">+ 100 free spins</span> with <span className="primary">no deposit required</span></>,
    home_banner_log_out_subtitle_theme_custom: (spinAmount) => <><span className="primary">{`+ ${spinAmount} free spins`}</span> with <span className="primary">no deposit required</span></>,
    home_banner_log_out_cta_theme: "Claim now"
}
import React from "react";
import { useSelector } from 'react-redux';

/** Theme */
import themes from "../themes";

/** Utilities */
import useText from "../spinsoft-core-client-subtree/utilities/use-text";

export default function RegistrationBannerThemeWolfGold() {
    const text = useText();
    const bonuses = useSelector((state) => state.bonuses.list);
    const theme = useSelector((state) => state.global.data.theme?.name);
    const bonus = bonuses[themes(theme, "phone_verification_promocode")];

    return (
        <>
            <img className="registration_banner_background animate__animated animate__fadeIn" src="https://spt-assets.b-cdn.net/orozino/themes/wolf_gold/registration_banner_background.jpg" />
            <img className="registration_banner_image registration_banner_image_wolf_gold animate__animated animate__fadeInRight" src="https://spt-assets.b-cdn.net/orozino/themes/wolf_gold/home_banner_image.png" />
            <img className="registration_banner_arrow animate__animated animate__fadeIn animate__delay-1s" src="https://spt-assets.b-cdn.net/orozino/themes/default/registration_banner_arrow.png" />
            <div className="registration_banner_container animate__animated animate__fadeIn animate__delay-1s">
                <div className="registration_banner_content">
                    <div className="registration_banner_content_background">
                        <p className="registration_banner_content_title">{bonus?.content?.badge?.toUpperCase() || '...'}</p>
                        <p className="registration_banner_content_subtitle">{text('registration_banner_subtitle')}</p>
                    </div>
                    <img className="registration_banner_content_badges" src="https://spt-assets.b-cdn.net/orozino/themes/default/registration_banner_badge.png" />
                </div>
            </div>
        </>
    )
}